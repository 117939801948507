#sliderTest{
    color:darkgreen;
    height:50;
    transition: height 3s;

}

#sliderTest:hover {
    height: 300px;
    color: crimson;
  }