@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&family=Josefin+Sans");

@tailwind base;
@tailwind components;
@tailwind utilities;


/*Firefox*/
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(115, 98, 202) cornflowerblue ;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

@media print {
  header, footer, aside, form, … {
      display: none;
  }
}
@media print {
  a:after {
    content: "("attr(href)")";
  }
}

*::-webkit-scrollbar-track {
  background: rgb(115, 98, 202);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgoldenrod;
  border-radius: 14px;
  border: 3px solid darkgoldenrod;
}


html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  /* height: 100%; */
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100%;
}

.editableContain{
  background-color: rgba(138, 64, 132, 0);
}
.editableContain:hover{
    cursor: pointer;
    background-color: #59d4fa6e;
    transition: all 0.3s ease-in;

}
.textareaFullNoScroll {
  height: 200px;
}
